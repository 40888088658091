import Form from './components/Form'
import mixin from '../approval/mixin'
import { DEF_ITEM } from '../approval/constants'
import { parseError } from '@/utils/api'

export default {
  mixins: [mixin],
  components: { Form },
  data() {
    return {
      mountedComponent: false,
      form: {
        name: '',
        document_approvals: [
          { position_id: null, mandatory: false, has_condition: false },
        ],
        signature_approvals: [
          { position_id: null, mandatory: true, has_condition: false },
        ],
      },
      fields: [{ key: 'name', label: 'Contract Workflow Name', _style: '' }],
      defaultForm: {
        name: '',
        document_approvals: [this.$deepClone(DEF_ITEM)],
        signature_approvals: [
          { position_id: null, mandatory: true, has_condition: false },
        ],
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.mountedComponent = true
    })
  },
  methods: {
    mapData(data) {
      if (!data.document_approvals.length) {
        data.document_approvals.push({
          position_id: null,
          mandatory: false,
          has_condition: false,
        })
      }

      if (!data.signature_approvals.length) {
        data.signature_approvals.push({
          position_id: null,
          mandatory: true,
          has_condition: false,
        })
      }

      data.document_approvals.forEach((el, index) => {
        data.document_approvals[index].condition_value = el.condition_value
          ? Number(el.condition_value).toFixed(2)
          : 0
        data.document_approvals[index].condition_value_2 = el.condition_value
          ? Number(el.condition_value_2).toFixed(2)
          : 0
      })

      return data
    },
    mapForm() {
      let form = JSON.parse(JSON.stringify(this.form))
      form.document_approvals = form.document_approvals.filter(
        (el) => el.position_id,
      )
      form.document_approvals.forEach((el, index) => {
        form.document_approvals[index].condition_value = parseInt(
          el.condition_value,
        )
        form.document_approvals[index].condition_value_2 = parseInt(
          el.condition_value_2,
        )
      })

      return form
    },
    handleEdit(id) {
      this.errors = {}
      this.$http.setting
        .contractsApprovalRounds(id)
        .then((res) => this.setNew(this.mapData(res.data.data)))
    },
    fetchData() {
      this.loadingTable = true
      this.$http.setting
        .contractsApprovalWorkflowList({
          params: {
            per_page: this.perPage,
            page: this.activePage,
            sort_field:
              this.sort && this.sort.column ? this.sort.column : undefined,
            sort_direction:
              this.sort && this.sort.state ? this.sort.state : undefined,
          },
        })
        .then((res) => {
          this.workflows = res.data.data
          this.pages = res.data.meta.last_page
          if (this.activePage > this.pages)
            this.activePage = this.activePage - 1
          this.key++
        })
        .finally(() => (this.loadingTable = false))
    },
    deleteWorkflow(id) {
      this.$http.setting
        .deleteContractsApprovalRounds(id)
        .then((res) => {
          this.noDelete = null
          this.$modal.close()
          this.fetchData()
          this.toast('success', res.data.message)
          this.setNew()
        })
        .catch(({ response }) => {
          this.errors = response.data.errors
            ? this.getErrors(response.data.errors)
            : {}
          this.toast('error', response.data.message)
          if (response.data.projects) {
            this.noDelete = response.data
            this.deleteId(id)
          }
        })
    },
    save() {
      let form = this.mapForm()
      if (!this.$refs.form.deepValidate()) {
        this.loading = true
        const action = form.id
          ? this.$http.setting.editContractsApprovalRounds(form)
          : this.$http.setting.createContractsApprovalRounds(form)
        action
          .then((res) => {
            this.toast('success', res.data.message)
            this.fetchData()
            this.setNew()
          })
          .catch((errors) => {
            if (
              errors.response.status === 409 &&
              errors.response.data.projects.length
            ) {
              this.copyModal(form, errors.response.data.projects)
            } else {
              this.$refs.form.validationErrors =
                parseError(errors).validationMessages || {}
            }
          })
          .finally(() => (this.loading = false))
      }
    },
    copyModal(form, projects) {
      this.$modal
        .open({
          close: 'Cancel',
          success: 'Copy',
          label: 'Impossible to edit workflow ' + form.name,
          size: 'lg',
          sublabel: `
              <p class="mb-0">The workflow is already used in projects</p>
              <ul style="list-style-type: none; padding: 0">
              ${projects
                .map(
                  (item) => `
                <li style="color: #005D9D">
                  <a href="/projects/${item.id}/dashboard" target="_blank">${item.name}</a>
                </li>
              `,
                )
                .join('')}
              </ul>
              <span>You can create a copy of the workflow with added changes.</span>
            `,
        })
        .then(() => {
          this.$modal.loading(true)
          this.$http.setting
            .copyContractsApproval(form)
            .then((res) => {
              this.toast('success', res.data.message)
              this.fetchData()
              this.setNew(res.data.data)
              this.$modal.close()
            })
            .catch((err) => {
              this.toast('error', err.response.data.message)
              this.$modal.loading(false)
            })
        })
    },
  },
}
